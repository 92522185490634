import React, { useState, useEffect, useRef } from "react"
import Button from "../components/button"
import bvReviewDataJSON from "../data/bvreviews.json"
import cn from "classnames"
import GalleryModal from "../components/galleryModal"
import Imgix from "react-imgix"
import NewFooter from "../components/newFooter"
import Rating from "../components/rating"
import SEO from "../components/seo"
import Slider from "react-slick"
import styles from "../styles/scss/pages/specialist.module.scss"
import Title from "../components/title"
import VideoImageBlock from "../components/videoImageBlock"
import YouTube from "react-youtube"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import { supSub } from "../services/util"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import ProductButtons from "../components/newProductButtons"

const Specialist = ({ ...props }) => {
    let sliderRef = useRef(null)
    let sliderRefMovil = useRef(null)

    const pageData = props.pageContext.page

    // Set Images for Carousels
    const ourStoryCarouselImages = [
        { src: "/img/specialist/frame1.jpg", alt: "Our Story Frame 1" },
        { src: "/img/specialist/frame2.jpg", alt: "Our Story Frame 2" },
        { src: "/img/specialist/frame3.jpg", alt: "Our Story Frame 3" },
        { src: "/img/specialist/frame4.jpg", alt: "Our Story Frame 4" },
        { src: "/img/specialist/frame5.jpg", alt: "Our Story Frame 5" },
        { src: "/img/specialist/frame6.jpg", alt: "Our Story Frame 6" },
        { src: "/img/specialist/frame7.jpg", alt: "Our Story Frame 7" },
    ]

    const blueCarouselCans = [
        {
            id: 0,
            src: "/img/specialist/blueCarouselCans/Gel Lube.png",
            alt: "WD-40® Specialist Gel Lube Can",
        },
        {
            id: 1,
            src: "/img/specialist/blueCarouselCans/Silicone.png",
            alt: "WD-40® Specialist Silicone Can",
        },
        {
            id: 2,
            src: "/img/specialist/blueCarouselCans/WLG.png",
            alt: "WD-40® Specialist White Lithium Grease Can",
        },
        {
            id: 3,
            src: "/img/specialist/blueCarouselCans/Penetrant.png",
            alt: "WD-40® Specialist Penetrant Can",
        },
        {
            id: 4,
            src: "/img/specialist/blueCarouselCans/Penetrant-straw.png",
            alt: "WD-40® Specialist Penentrant with Flexible Straw",
        },
        {
            id: 5,
            src: "/img/specialist/blueCarouselCans/Contact Cleaner.png",
            alt: "WD-40® Specialist Contact Cleaner",
        },
        {
            id: 6,
            src: "/img/specialist/blueCarouselCans/Dry Lube.png",
            alt: "WD-40® Specialist Dry Lube",
        },
    ]

    const whiteRowCans = [
        {
            id: 0,
            src: "/img/specialist/whiteRowCans/00.png",
            alt: "WD-40® Specialist Gel Lube Can",
        },
        {
            id: 1,
            src: "/img/specialist/whiteRowCans/01.png",
            alt: "WD-40® Specialist Silicone Can",
        },
        {
            id: 2,
            src: "/img/specialist/whiteRowCans/02.png",
            alt: "WD-40® Specialist White Lithium Grease Can",
        },
        {
            id: 3,
            src: "/img/specialist/whiteRowCans/03.png",
            alt: "WD-40® Specialist Penetrant Can",
        },
        {
            id: 4,
            src: "/img/specialist/whiteRowCans/strawout.png",
            alt: "WD-40® Specialist Penentrant with Flexible Straw",
        },
        {
            id: 5,
            src: "/img/specialist/whiteRowCans/05.png",
            alt: "WD-40® Specialist Contact Cleaner",
        },
        {
            id: 6,
            src: "/img/specialist/whiteRowCans/06.png",
            alt: "WD-40® Specialist Dry Lube",
        },
    ]

    // Set Icon per Product
    const productIcons = index => {
        switch (index) {
            case 0:
                return [
                    ["/img/specialist/gears.png", "Gears"],
                    ["/img/specialist/linkages.png", "Linkages"],
                    ["/img/specialist/cables.png", "Cables"],
                    ["/img/specialist/bearings.png", "Bearings"],
                ]
            case 1:
                return [
                    ["/img/specialist/linkages.png", "Linkages"],
                    ["/img/specialist/pulleys.png", "Pulleys"],
                    ["/img/specialist/cables.png", "Cables"],
                    ["/img/specialist/guiderails.png", "Guide Rails"],
                ]
            case 2:
                return [
                    ["/img/specialist/hinges.png", "Hinges"],
                    ["/img/specialist/gears.png", "Gears"],
                    ["/img/specialist/latches.png", "Latches"],
                    ["/img/specialist/doortracks.png", "Door Tracks"],
                ]
            case 3:
                return [
                    ["/img/specialist/threads.png", "Threads"],
                    ["/img/specialist/nuts.png", "Nuts"],
                    ["/img/specialist/locks.png", "Locks"],
                    ["/img/specialist/bolts.png", "Bolts"],
                ]
            case 4:
                return [
                    ["/img/specialist/threads.png", "Threads"],
                    ["/img/specialist/nuts.png", "Nuts"],
                    ["/img/specialist/locks.png", "Locks"],
                    ["/img/specialist/bolts.png", "Bolts"],
                ]
            case 5:
                return [
                    ["/img/specialist/circuitboards.png", "Circuit Boards"],
                    ["/img/specialist/electricpanels.png", "Electric Panels"],
                    ["/img/specialist/switches.png", "Switches"],
                    ["/img/specialist/controls.png", "Controls"],
                ]
            case 6:
                return [
                    ["/img/specialist/powertools.png", "Power Tools"],
                    ["/img/specialist/blades.png", "Blades"],
                    ["/img/specialist/bits.png", "Bits"],
                    ["/img/specialist/doortracks.png", "Door Tracks"],
                ]
            default:
                return [
                    ["/img/specialist/gears.png", "gears"],
                    ["/img/specialist/linkages.png", "linkages"],
                    ["/img/specialist/cables.png", "cables"],
                    ["/img/specialist/bearings.png", "bearings"],
                ]
        }
    }

    // Associate product reviews with specialist products to get reviews.
    const productReviews = id => {
        return bvReviewDataJSON.find(review => review.id === id)
    }

    // Get Specialist Products, w/ personalied icon and review.
    const specialistProducts = pageData.relatedProducts.map((item, index) => {
        return {
            ...item,
            productIcon: productIcons(index),
            productReview: productReviews(item.bazaarVoiceId),
        }
    })

    const [selectedProductIndex, setSelectedProductIndex] = useState(0)
    const [forceRender, setforceRender] = useState(false)
    const [activeSlide, setActiveSlide] = useState(selectedProductIndex)
    const [modalContent, setModalContent] = useState({})
    const [currentIndex, setCurrentIndex] = useState(0)
    const [isModalOpen, setIsModalOpen] = useState(false)

    const setImageToModal = (item, index) => {
        setCurrentIndex(index)
        setModalContent({ ...item })
        setIsModalOpen(!isModalOpen)
    }

    // Input new Selected Product
    const handleProductChange = index => {
        setActiveSlide(index)
        if (sliderRef) {
            const slick = sliderRef.current
            const slickMovil = sliderRefMovil.current

            slick.slickGoTo(index)
            slickMovil.slickGoTo(index)
        }
    }

    const handleMobileOnClick = () => {
        if (typeof window !== "undefined") {
            document.getElementById("productDetail").scrollIntoView()
        }
    }

    // Custom Arrows for carousels
    const OurStoryArrow = props => {
        let className = props.type === "next" ? "nextArrow" : "prevArrow"
        className += " arrow"
        const char =
            props.type === "next" ? (
                <i
                    className="fa fa-angle-right"
                    aria-hidden="true"
                    style={{ right: "5%" }}
                />
            ) : (
                <i
                    className="fa fa-angle-left"
                    aria-hidden="true"
                    style={{ left: "5%" }}
                />
            )
        return (
            <span className={className} onClick={props.onClick}>
                {char}
            </span>
        )
    }

    const arrowsControl = (productIndex, flow) => {
        if (sliderRef) {
            const slidePosition =
                flow === "next"
                    ? productIndex === 6
                        ? 0
                        : productIndex + 1
                    : productIndex === 0
                        ? 6
                        : productIndex - 1
            handleProductChange(slidePosition)
        }
    }

    const ProductCanourStoryArrow = props => {
        let className = props.type === "next" ? "nextArrow" : "prevArrow"
        className += " arrow"
        const char =
            props.type === "next" ? (
                <i
                    className="fa fa-angle-right"
                    aria-hidden="true"
                    style={{ right: "5%" }}
                />
            ) : (
                <i
                    className="fa fa-angle-left"
                    aria-hidden="true"
                    style={{ left: "5%" }}
                />
            )
        return (
            <span
                className={className}
                onClick={() => arrowsControl(props.productIndex, props.type)}
            >
                {char}
            </span>
        )
    }

    useEffect(() => {
        setforceRender(true)
    }, [])

    // Our Story Carousel Settings
    const ourStoryCarouselsettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        lazyLoad: true,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <OurStoryArrow type="next" />,
        prevArrow: <OurStoryArrow type="prev" />,
    }

    // Blue Background Carousel Settings
    const productCansCarouselsettings = {
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        className: "center",
        centerMode: true,
        centerPadding: "20px",
        mobileFirst: true,
        swipe: false,
        // focusOnSelect: true,
        initialSlide: selectedProductIndex,
        nextArrow: (
            <ProductCanourStoryArrow
                type="next"
                productIndex={selectedProductIndex}
            />
        ),
        prevArrow: (
            <ProductCanourStoryArrow
                type="prev"
                productIndex={selectedProductIndex}
            />
        ),
        beforeChange: function (old, index) {
            setSelectedProductIndex(index)
        },
        responsive: [
            {
                breakpoint: 816,
                settings: {
                    initialSlide: selectedProductIndex,
                    slidesToScroll: 1,
                    slidesToShow: 1,
                    infinite: false,
                    centerMode: false,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    initialSlide: selectedProductIndex,
                    slidesToScroll: 1,
                    slidesToShow: 1,
                    infinite: false,
                    centerMode: false,
                },
            },
        ],
    }

    const productCansCarouselsettingsMovil = {
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        className: "center",
        centerMode: true,
        centerPadding: "20px",
        mobileFirst: true,
        swipe: false,
        initialSlide: selectedProductIndex,
        nextArrow: (
            <ProductCanourStoryArrow
                type="next"
                productIndex={selectedProductIndex}
            />
        ),
        prevArrow: (
            <ProductCanourStoryArrow
                type="prev"
                productIndex={selectedProductIndex}
            />
        ),
        beforeChange: function (old, index) {
            setSelectedProductIndex(index)
        },
    }

    return (
        <>
            <Helmet>
                <noscript
                    dangerouslySetInnerHTML={{
                        __html: `<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-K29TWX" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`,
                    }}
                />
                <meta name="format-detection" content="telephone=no" />
                {/* Price Spider */}
                <meta name="ps-key" content="1675-59d8056be4625d01baa9abaa" />
                <meta name="ps-country" content="US" />
                <meta name="ps-language" content="en" />
            </Helmet>
            {pageData.seo.title && (
                <h1 style={{ display: "none" }}>{pageData.seo.title}</h1>
            )}
            <SEO page={pageData} />
            <div className={styles.menuContainer}>
                <div className={styles.menuFull}>
                    <div className={styles.logoMenu}>
                        <Link to="/">
                            <Imgix
                                className={styles.menuLogo}
                                src="/img/logo.svg"
                                sizes="100vm"
                                htmlAttributes={{ alt: "WD-40 Logo" }}
                            />
                        </Link>
                    </div>
                    <div className={styles.menuItems}>
                        <div id={"nav-bar"} className={styles.menuItem}>
                            <a href="https://www.wd40.com/specialist#specialist-video">Video</a>
                            <a href="https://www.wd40.com/specialist#our-story">Our Story</a>
                            <a href="https://www.wd40.com/specialist#specialist-products">Explore</a>
                        </div>
                    </div>
                </div>
                <Imgix
                    sizes="100vm"
                    htmlAttributes={{ alt: "Specialist Product Showcase" }}
                    src={pageData.heroImage.url}
                    className={styles.hero}
                />
            </div>

            <div
                style={{
                    maxWidth: "1360px",
                    margin: "auto",
                    fontFamily: "Barlow",
                }}
            >
                <div className={styles.videoContainer}>
                    <div
                        id={"specialist-video"}
                        className={styles.iframeContainer}
                    >
                        <YouTube
                            videoId={"ynzvb0iFeOc"}
                            opts={{
                                height: '390',
                                width: 'auto',
                                playerVars: {
                                    rel: 0,
                                },
                            }}
                        />
                    </div>
                </div>
                <div id="our-story">
                    <Slider
                        {...ourStoryCarouselsettings}
                        className={styles.ourStoryCarousel}
                    >
                        {ourStoryCarouselImages &&
                            ourStoryCarouselImages.map((item, key) => {
                                return (
                                    <div
                                        key={key}
                                        className={
                                            styles.ourStoryCarouselImageContainer
                                        }
                                    >
                                        <Imgix
                                            sizes="100vm"
                                            src={item.src}
                                            htmlAttributes={{ alt: item.alt }}
                                            className={
                                                styles.ourStoryCarouselImage
                                            }
                                        />
                                    </div>
                                )
                            })}
                    </Slider>
                </div>
                <div
                    id="specialist-products"
                    className={styles.specialistProductCarouselContainer}
                >
                    <div className={styles.specialistBlueContainer}>
                        <div
                            className={styles.specialistBlueContainerImage}
                            style={{
                                backgroundImage:
                                    'url("/img/specialist/blue-gradient.png")',
                                overflow: "hidden",
                            }}
                        >
                            <Title
                                className={styles.specialistBlueContainerTitle}
                                value="Explore the WD-40 Specialist® Line"
                            />
                            <div className="slider-specialist-desktop">
                                <Slider
                                    ref={sliderRef}
                                    {...productCansCarouselsettings}
                                    className={styles.productCansCarousel}
                                >
                                    {blueCarouselCans &&
                                        blueCarouselCans.map((item, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className={
                                                        styles.blueCanImageContainer
                                                    }
                                                >
                                                    <Imgix
                                                        sizes="100vm"
                                                        src={item.src}
                                                        htmlAttributes={{ alt: item.alt }}
                                                        className={
                                                            item.id ===
                                                                selectedProductIndex
                                                                ? styles.productCansCarouselImageSelected
                                                                : styles.productCansCarouselImage
                                                        }
                                                    />
                                                </div>
                                            )
                                        })}
                                </Slider>
                            </div>

                            <div className="slider-specialist-movil">
                                <Slider
                                    ref={sliderRefMovil}
                                    {...productCansCarouselsettingsMovil}
                                    className={styles.productCansCarousel}
                                >
                                    {blueCarouselCans &&
                                        blueCarouselCans.map((item, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className={
                                                        styles.blueCanImageContainer
                                                    }
                                                >
                                                    <Imgix
                                                        sizes="100vm"
                                                        onClick={() =>
                                                            handleMobileOnClick()
                                                        }
                                                        src={item.src}
                                                        htmlAttributes={{ alt: item.alt }}
                                                        className={
                                                            item.id ===
                                                                selectedProductIndex
                                                                ? styles.productCansCarouselImageSelected
                                                                : styles.productCansCarouselImage
                                                        }
                                                    />
                                                </div>
                                            )
                                        })}
                                </Slider>
                            </div>
                        </div>
                    </div>
                    <div className={styles.specialistProductListContainer}>
                        {specialistProducts &&
                            specialistProducts.map((item, index) => {
                                var nametodisplay =
                                    item.upc === "0 79567 30048 9"
                                        ? "Penetrant with EZ-REACH"
                                        : item.displayName.split("®").pop()
                                return (
                                    <div
                                        key={index}
                                        className={styles.specialistProductItem}
                                        onClick={() =>
                                            handleProductChange(index)
                                        }
                                    >
                                        <Imgix
                                            sizes="100vm"
                                            src={whiteRowCans[index].src}
                                            htmlAttributes={{ alt: item.alt }}
                                            className={
                                                whiteRowCans[index].alt ===
                                                    "WD-40® Specialist Gel Lube Can"
                                                    ? styles.gelLubeCan
                                                    : ""
                                            }
                                        />
                                        <div
                                            className={
                                                styles.specialistProductItemTitleContainer
                                            }
                                        >
                                            <Title
                                                className={
                                                    whiteRowCans[index].id ===
                                                        selectedProductIndex
                                                        ? styles.specialistProductItemSelected
                                                        : styles.specialistProductItemTitle
                                                }
                                                value={nametodisplay}
                                            />
                                        </div>
                                    </div>
                                )
                            })}
                    </div>
                    <div className={styles.specialistProductDetailContainer}>
                        <div
                            id="productDetail"
                            className={styles.specialistProductDetailLeftColumn}
                        >
                            <div
                                className={
                                    styles.specialistProductDetailLeftColumnProductInformation
                                }
                            >
                                {specialistProducts[selectedProductIndex]
                                    .displayName && (
                                        <Title
                                            value={
                                                specialistProducts[
                                                    selectedProductIndex
                                                ].displayName
                                            }
                                            className={
                                                styles.productInformationTitle
                                            }
                                            style={{ textAlign: "left" }}
                                        />
                                    )}

                                {specialistProducts[selectedProductIndex]
                                    .productReview && (
                                        <Rating
                                            value={
                                                specialistProducts[
                                                    selectedProductIndex
                                                ].productReview
                                            }
                                            bold
                                            spacing
                                        />
                                    )}
                                <div
                                    className={styles.productInformationWysiwyg}
                                    dangerouslySetInnerHTML={{
                                        __html: supSub(
                                            specialistProducts[
                                                selectedProductIndex
                                            ].content[0].body
                                        ),
                                    }}
                                />
                            </div>
                        </div>

                        <div
                            className={
                                styles.specialistProductDetailRightColumn
                            }
                        >
                            <div
                                className={
                                    styles.specialistProductDetailWorksGreatOn
                                }
                            >
                                <Title
                                    className={
                                        styles.specialistProductItemWorksGreatOnTitle
                                    }
                                    value={"Works great on:"}
                                />
                                <div
                                    className={
                                        styles.specialistProductItemWorksGreatOnIcons
                                    }
                                >
                                    {specialistProducts[selectedProductIndex]
                                        .productIcon &&
                                        specialistProducts[
                                            selectedProductIndex
                                        ].productIcon.map((item, key) => (
                                            <div
                                                key={key}
                                                className={
                                                    styles.specialistProductItemWorksGreatOnIndividualIcon
                                                }
                                            >
                                                <Imgix
                                                    sizes="100vm"
                                                    className={
                                                        styles.productImage
                                                    }
                                                    src={item[0]}
                                                    htmlAttributes={{ alt: '' }}
                                                />
                                                <Title
                                                    className={
                                                        styles.specialistProductIndividualIconTitle
                                                    }
                                                    value={item[1]}
                                                />
                                            </div>
                                        ))}
                                </div>
                            </div>

                            <div
                                className={
                                    styles.specialistProductDetailContents
                                }
                            >
                                <Title
                                    className={
                                        styles.specialistProductDetailTitle
                                    }
                                    value={"View Product Images and Videos"}
                                />
                                <div
                                    className={
                                        styles.specialistProductDetailImagesVideos
                                    }
                                >
                                    {specialistProducts[selectedProductIndex]
                                        .images &&
                                        specialistProducts[
                                            selectedProductIndex
                                        ].images.map((item, index) => (
                                            <div
                                                key={item.id}
                                                className={styles.selected}
                                            >
                                                <Imgix
                                                    sizes="100vm"
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    src={item.url}
                                                    htmlAttributes={{ alt: '' }}
                                                    onClick={() =>
                                                        setImageToModal(
                                                            item,
                                                            index
                                                        )
                                                    }
                                                />
                                            </div>
                                        ))}
                                    {isModalOpen && (
                                        <GalleryModal
                                            images={
                                                specialistProducts[
                                                    selectedProductIndex
                                                ].images
                                            }
                                            selectedProductIndex={
                                                selectedProductIndex
                                            }
                                            videos={
                                                specialistProducts[
                                                    selectedProductIndex
                                                ].videos
                                            }
                                            isModalOpen={isModalOpen}
                                            setCurrentIndex={setCurrentIndex}
                                            currentIndex={currentIndex}
                                            setIsModalOpen={setIsModalOpen}
                                        />
                                    )}

                                    {specialistProducts[selectedProductIndex]
                                        .videos &&
                                        specialistProducts[
                                            selectedProductIndex
                                        ].videos.map(v => (
                                            <VideoImageBlock
                                                key={v.video.providerUid}
                                                imageClass={styles.videoImage}
                                                containerClass={
                                                    styles.videoContainer
                                                }
                                                hideBody
                                                youtubeVideoId={
                                                    v.video.providerUid
                                                }
                                                image={v.video.thumbnailUrl}
                                            />
                                        ))}
                                </div>
                            </div>

                            <div
                                className={styles.specialistProductDetailButton}
                            >
                                <ProductButtons
                                    className={cn(
                                        styles.priceSpiderNew,
                                        "ps-widget"
                                    )}
                                    product={specialistProducts[
                                        selectedProductIndex
                                    ]}
                                    verticalButtons={true}
                                    withoutAnchor={true}
                                    displayName="Buy this product"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.ProductInformationRedAnchorContainer}>
                    <a
                        className={styles.ProductInformationRedAnchor}
                        href="/products/?category=specialist"
                    >
                        Explore All WD-40 Brand Products {`>>`}
                    </a>
                </div>
            </div>
            <NewFooter />
            <style>
                {`
          #our-story > div > ul > li > button::before{ 
            color: transparent;
          }
          #our-story > div > ul > li > button{ 
            color: transparent;
            border: 1px solid #d2002c;
            border-radius: 50%;
            width: 12px;
            height: 12px;
          }
          #our-story > div > ul > li.slick-active > button {
            color: #d2002c !important;
            background-color: #d2002c;
            border-radius: 50%;
          }

          #our-story .slick-slide img {
            display: inline-flex;
          }

          .slider-specialist-desktop{
            display: none;
          }

          @media (min-width: 816px) {
            .slider-specialist-desktop{
              display: block;
            }
            .slider-specialist-movil{
              display: none;
            }
          }

          @media (max-width: 816px) {
            #our-story .slick-dots li{
              width: 12px;
              height: 12px;
            }
            #our-story > div > ul > li > button{ 
              width: 5px;
              height: 5px;
            }
          }

        `}
            </style>
        </>
    )
}

export default Specialist