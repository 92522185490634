import React, { Component } from "react"
import CustomModal from "./customModal"
import styles from "../styles/scss/components/videoImageBlock.module.scss"
import cn from "classnames"
export default class Index extends Component {
    state = { openVideo: false }

    handleClick() {
        this.setState({ openVideo: true })
    }

    render() {
        let {
            youtubeVideoId,
            image,
            title,
            containerClass,
            imageClass,
            titleClass,
            id,
        } = this.props
        return (
            <div
                id={id}
                className={cn(
                    styles.container,
                    containerClass || styles.defaultContainer
                )}
            >
                <div
                    style={{ backgroundImage: `url(${image})` }}
                    className={cn(
                        styles.image,
                        imageClass || styles.defaultImage
                    )}
                >
                    {youtubeVideoId && (
                        <a
                            href="#"
                            onClick={() => this.handleClick()}
                        >
                            <div className={styles.circlePlay}>
                                <i className="fa fa-play" />
                            </div>
                        </a>
                    )}
                </div>
                {title && (
                    <p className={titleClass || styles.defaultTitle}>{title}</p>
                )}
                {youtubeVideoId && (
                    <CustomModal
                        openVideo={this.state.openVideo}
                        setOpenVideo={() => this.setState({ openVideo: false })}
                        youtubeVideoId={youtubeVideoId}
                    />
                )}
            </div>
        )
    }
}
